/* Tooltip styles */
.edwt__tooltip__content {
  /* transform-origin: var(--radix-tooltip-content-transform-origin); */
  animation: slide-in 0.25s;
  @apply ease-in-out;
}

.edwt__tooltip__arrow {
  overflow: visible;
}
.edwt__tooltip__arrow > * {
  @apply fill-white drop-shadow-md;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
