/* Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Components */
@import "./tooltip.css";

.edwt-container {
  @apply mx-auto w-full px-3 xs:px-5;
}

html,
body,
#__next {
  height: 100%;
}

.mapboxgl-ctrl-bottom-left {
  z-index: unset !important;
}
